$animation-fast-time: 0.2s;
$animation-fast-timing: ease-out;

.animation-fast {
  transition-timing-function: $animation-fast-timing;
  transition-duration: $animation-fast-time;
}

@keyframes animated-background {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
}


* {
  @extend .animation-fast;
}
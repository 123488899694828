$type-brand-font: "reklame-script", sans-serif;

$type-title-font: "freight-text-pro", sans-serif;
$type-body-font: "freight-sans-pro", serif;

$ms-base: 18px;
$ms-ratio: $major-third;

$type-body-size: ms(0);
$type-h6-size: ms(1);
$type-h5-size: ms(1);
$type-h4-size: ms(2);
$type-h3-size: ms(3);
$type-h2-size: ms(4);
$type-h1-size: ms(5);

body {
  font-size: $type-body-size;
  font-family: $type-body-font;
}

p {
  font-size: $type-body-size;
  font-family: $type-body-font;
  line-height: ms(2);
}

h1 {
  font-size: $type-h1-size;
}

h2 {
  font-size: $type-h2-size;
}

h3 {
  font-size: $type-h3-size;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $type-title-font;
}
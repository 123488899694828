*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

%clearfix {
  *zoom: 1;
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

%center-vertical {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

#container {}

%row {
  @extend %clearfix;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

%column {
  float: left;
  width: 100%;
}

main {
  width: 100%;
  margin: 0 auto;
  @extend %row;
}

article {
  & > p {}
}
@import "modular-scale/stylesheets/modular-scale";

@import "global";
@import "colors";
@import "queries";
@import "type";
@import "animation";
@import "layout";
@import "portfolio";

body {
    width: 100%;
}

main {
    & > section {
        &:nth-child(1) {
            margin: 0 0 ms(1) 0;
        }
        margin: ms(1) 0;
    }
}

.social {
    text-align: center;
    height: ms(4);
    line-height: ms(9);
    margin: 0 ms(4);
    padding: 0;
    display: inline-block;
    @include respond-to(small) {
        line-height: ms(10);
        height: ms(10);
        padding: ms(4) 0;
    }

    @include respond-to(medium) {
        height: ms(12);
        line-height: ms(12);
    }

    a {
        @extend %center-vertical;
        width: 64px;
        height: 64px;
        float: left;
        display: inline-block;
        &:nth-child(n+2) {
            margin: 0 0 0 ms(4);
        }
        @include respond-to(medium) {
            width: 96px;
            height: 96px;
            &:nth-child(n+2) {
                margin: 0 0 0 ms(4);
            }
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
        }
        &:hover {
            margin-top: -8px;
        }
    }
    #social-twitter {}
}

#portrait-area {
    display: inline-block;
    position: relative;
    &:hover {
        .dog {
            z-index: 2;
        }
        .scout {
            top: -(ms(1));
            left: -(ms(3));
        }
        .clem {
            top: ms(4);
            left: -(ms(3));
        }
    }
}

#about {
    margin: 0 auto;
    text-align: center;
    img.self {
        border-radius: ms(9);
        width: ms(9);
        height: ms(9);
        object-fit: cover;
        @include respond-to(small) {
            border-radius: ms(10);
            height: ms(10);
            width: ms(10);
        }

        @include respond-to(medium) {
            border-radius: ms(12);
            height: ms(12);
            width: ms(12);
        }
    }
    .dog {
        &:hover {
            z-index: 4;
        }
        position: absolute;
        z-index: -100;
        width: ms(4);
        height: ms(4);
        @include respond-to(small) {
            height: ms(6);
            width: ms(6);
        }

        @include respond-to(medium) {
            height: ms(8);
            width: ms(8);
        }

        img {
            width: ms(4);
            height: ms(4);
            border-radius: ms(4);
            object-fit: cover;
            @include respond-to(small) {
                border-radius: ms(6);
                height: ms(6);
                width: ms(6);
            }

            @include respond-to(medium) {
                border-radius: ms(8);
                height: ms(8);
                width: ms(8);
            }

            &:hover {
                width: ms(6);
                height: ms(6);
                @include respond-to(small) {
                    border-radius: ms(8);
                    height: ms(8);
                    width: ms(8);
                }
            }
        }
    }
    .scout {
        left: ms(1);
        top: ms(1);
    }
    .clem {
        left: ms(4);
        top: ms(1);
    }
    & > div.blurb {
        font-family: $type-body-font;
        text-align: center;
        font-style: italic;
        margin: 0 0 (ms(0) / 2) -4px;
        font-size: ms(0);
        & > span:nth-child(n+2) {
            &::before {
                content: '&';
                margin: 0 8px 0 4px;
            }
        }
    }
    & > div.more {
        text-align: center;
        margin: 0 0 ms(5) -20px;
        @include respond-to(small) {
            margin: 0 0 ms(1) -20px;
        }

        font-style: italic;
        font-size: ms(0);
        & > a {
            margin: 0 0 0 ms(0);
        }
    }
}

a {
    color: $color-teal;
    &:hover {
        color: $color-light-blue;
    }
}

.brand {
    a {
        text-decoration: none;
    }
    text-align: center;
    font-family: $type-brand-font;
    margin: 0 auto;
    padding: 0;
    /*background: url("http://digitalrelativity.com/wp-content/uploads/2011/07/clouds.jpg");
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-position: 100% 0;
    transition: all 1s linear;
    &:hover {
      background-position: 0% 0;
    }*/
}

.main {
    margin: 0 auto;
    width: 100%;
    text-align: center;
    padding: ms(2) 0;
    @include respond-to(medium) {
        padding: ms(2) ms(12);
    }
}

.about {
    text-align: left;
    font-size: $type-h5-size;
    li {
        margin: 0 0 ms(0) 0;
    }
}
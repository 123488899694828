$color-teal: #418D99;
$color-light-blue: #71D1E0;
$color-light-green: #D2FFEC;
$color-coral: #FF9392;
$color-rose: #CC94AE;
$color-white: #ffffff;

// $color-primary-2:;
// $color-accent:;
// $color-accent-2:;
//$color-background:;
//$color-primary: ;

body {
  background-color: $color-white;
}

h1, h2, h3, h4, h5, h6 {
  color: $color-teal;
}
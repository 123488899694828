%portfolio-piece-overlay {
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

#portfolio-pieces {
    width: 100%;
    @extend %row;
}

.portfolio-piece {
    @extend %column;
    margin: 0 auto;
    text-align: center;
    height: 320px;
    overflow: hidden;
    width: 100%;
    &:nth-child(n+1) {
        background: rgba(lighten($color-rose, 30%), 0.7);
    }
    &:nth-child(2n+2) {
        background: rgba(lighten($color-light-blue, 20%), 0.7);
    }
    &:nth-child(3n+3) {
        background: rgba(lighten($color-light-green, 0%), 0.7);
    }
    &:nth-child(4n+4) {
        background: rgba(lighten($color-light-green, 20%), 0.7);
    }
    & > div {
        position: relative;
        margin: ms(0);
        height: 100%;
    }
    @include respond-to(small) {
        width: 50%;
        height: 320px;
        float: left;
        &:nth-child(even) > div {
            margin-left: ms(0) / 2;
        }
        &:nth-child(odd) > div {
            margin-right: ms(0) / 2;
        }
    }

    @include respond-to(medium) {
        height: 320px * 1.5;
        width: 33%;
        &:nth-child(3n+1) {
            width: 33%;
            & > div {
                margin-left: 2%;
                margin-right: 2%;
            }
        }
        &:nth-child(3n+2) {
            width: 34%;
            & > div {
                margin-left: 2%;
                margin-right: 2%;
            }
        }
        &:nth-child(3n+3) {
            width: 33%;
            & > div {
                margin-right: 2%;
                margin-left: 2%;
            }
        }
    }

    &:hover {
        .overlay {
            left: 0px;
        }
        .img {
            opacity: .2;
        }
    }
    overflow: hidden;
    .overlay {
        text-decoration: none;
        overflow: hidden;
        @extend .animation-fast;
        @extend %portfolio-piece-overlay;
        opacity: 0.8;
        left: 0px;
        transition: all .2s ease-in;
        &:hover {
            opacity: .8;
            cursor: pointer;
        }
        & > div {
            @extend %center-vertical;
        }
        &:before {
            @extend %portfolio-piece-overlay;
            content: '';
            background: darken($color-teal, 20%);
            z-index: -1;
        }
        @include respond-to(small) {
            opacity: 0;
            left: -100px;
        }

        @media (-webkit-min-device-pixel-ratio: 2) {
            opacity: 0;
            left: 0;
        }
    }
    .name, .category {
        margin: 0;
        text-align: center;
        font-weight: bold;
        font-size: $type-h3-size;
        left: 0%;
    }
    .name {
        color: $color-white;
        text-transform: uppercase;
        margin: ms(2) 0 0 0;
        font-family: $type-body-font;
        text-decoration: underline;
    }
    .category {
        font-style: italic;
        text-transform: lowercase;
        color: $color-rose;
        a {}
    }
    .img {
        width: 100%;
        height: 100%;
        opacity: 1;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.issuu-container {
    width: 100%;
    overflow: hidden;
    .issuuembed {
        height: 500px;
        margin-right: calc(100%/-9);
        div:nth-child(2) {
            display: none;
        }
    }
}

.portfolio-display {
    margin: 0 auto;
    width: 100%;
    text-align: center;
    padding: ms(9) ms(6);
    @include respond-to(medium) {
        padding: ms(3) ms(6);
    }

    h2 {
        text-transform: lowercase;
        font-style: italic;
        font-size: $type-h1-size;
        @include respond-to(medium) {
            font-size: $type-h1-size * 2;
        }

        margin: ms(1) 0 ms(0) 0;
        color: $color-coral;
    }
    h3 {
        margin: 0 0 ms(4) 0;
        text-transform: lowercase;
        font-style: italic;
        color: darken($color-light-green, 10%);
        &:before {
            content: '(';
        }
        &:after {
            content: ')';
        }
    }
}

.images-container {}

.portfolio-image {
    margin: 0 auto;
    max-width: 100%;
    img {
        max-width: 100%;
        object-fit: fill;
    }
}